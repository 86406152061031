import type {
  HybrisCustomerAddressBook,
  HybrisAddress,
  HybrisCustomerTitle,
  AddressForm,
  Country,
} from '~/integrations/hybris/src/types';

export const hybrisCustomerTitles: HybrisCustomerTitle[] = [
  { name: 'Mr', code: 'Mr' },
  { name: 'Mrs', code: 'Mrs' },
  { name: 'Miss', code: 'Miss' },
  { name: 'Ms', code: 'Ms' },
  { name: 'Mr and Mrs', code: 'Mr and Mrs' },
  { name: 'Sir', code: 'Sir' },
  { name: 'Dr', code: 'Dr' },
  { name: 'Prof', code: 'Prof' },
  { name: 'Lord', code: 'Lord' },
  { name: 'Lady', code: 'Lady' },
  { name: 'Rev', code: 'Rev' },
  { name: 'Dame', code: 'Dame' },
  { name: 'Master', code: 'Master' },
  { name: '', code: 'NA' },
]

const addressGetters = {
  getMappedAddresses: (data: HybrisCustomerAddressBook): HybrisAddress[] => {
    // Combine both additional shipping addresses and the single billing address into one array
    if (data) {
      return [
        ...(data.additionalAddresses || []),
        ...(data.billingAddress ? [data.billingAddress] : [])
      ];
    }

    return [];
  },
  getAddresses: (addresses: HybrisAddress[], criteria?: Record<string, any>): HybrisAddress[] => {
    if (!criteria) {
      return addresses;
    }

    return addresses.filter(address =>
      Object.entries(criteria).every(([key, value]) => address[key as keyof HybrisAddress] === value)
    );
  },
  getTotal: (addresses: HybrisAddress[]): number => addresses?.length || 0,
  getPostCode: (address: HybrisAddress): string => address?.postalCode || '',
  getStreetName: (address: HybrisAddress): string => address?.line1 || '',
  getCity: (address: HybrisAddress): string => address?.town || '',
  getFirstName: (address: HybrisAddress): string => address?.firstName || '',
  getLastName: (address: HybrisAddress): string => address?.lastName || '',
  getCountry: (address: HybrisAddress): Country => address?.country || null,
  getPhone: (address: HybrisAddress): string => address?.phone || '',
  getEmail: (address: HybrisAddress): string => address?.email || '',
  getApartmentNumber: (address: HybrisAddress): string => address?.line2 || '',
  getProvince: (address: HybrisAddress): string => address?.country?.isocode || '',
  getId: (address: HybrisAddress): string => address?.id || '',
  isDefault: (address: HybrisAddress | AddressForm): boolean =>
    (address?.isDefaultShippingAddress && address?.isDefaultBillingAddress) || false,
  isDefaultShipping: (address: HybrisAddress | AddressForm): boolean => address?.isDefaultShippingAddress || false,
  isDefaultBilling: (address: HybrisAddress | AddressForm): boolean => address?.isDefaultBillingAddress || false,
  getCountryName: (address: HybrisAddress): string => address?.country?.name || '',
};

export default addressGetters;
