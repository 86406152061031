
























































































































import {
  defineComponent,
  useContext,
  ref,
  computed,
  useFetch,
  onMounted,
} from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
import { useCountrySearch } from '~/composables/useCountrySearch';
import type { Country } from '~/modules/GraphQL/types';
import { useMyAccountNavigation } from '@theme/modules/customer/stores/myAccountNavigation';
import EmptySection from '@theme/components/molecules/EmptySection.vue';
import RemoveHybrisAddressSidebar from '@hybris/components/organisms/RemoveHybrisAddressSidebar.vue';
import AddressBookSkeleton from '@theme/modules/customer/components/skeleton/AddressBookSkeleton.vue';
import HybrisUserAddressDetails from '@hybris/components/organisms/HybrisUserAddressDetails.vue';
import type { HybrisAddress } from '@hybris/types';
import useHybrisAddresses from '@hybris/composables/useHybrisAddresses';
import addressGetters from '@hybris/getters/hybrisAddressesGetters';

export default defineComponent({
  name: 'HybrisAddressesDetails',
  components: {
    SfButton,
    HybrisUserAddressDetails,
    EmptySection,
    LazyHydrate,
    RemoveHybrisAddressSidebar,
    AddressBookSkeleton
  },
  middleware: 'is-authenticated',
  setup() {
    const { localePath, i18n } = useContext();
    const userAddresses = ref<HybrisAddress[]>([]);
    const countries = ref<Country[]>([]);
    const {
      getAddressBook,
      deleteAddress,
      isRemoveAddressSidebarOpened,
      toggleRemoveAddressSidebar,
      setRemovableAddress,
      removableAddress,
    } = useHybrisAddresses();
    const { load: loadCountries } = useCountrySearch();
    const breadcrumbsStore = useMyAccountNavigation();
    const additionalAddressesTitle = computed(() => {
      return i18n.t('My additional addresses ({0})', {
        0: additionalAddresses.value.length,
      }) as string;
    });

    const { fetch } = useFetch(async () => {
      const [addressesResponse, countriesResponse] = await Promise.all([getAddressBook(), loadCountries()]);
      userAddresses.value = addressesResponse
      countries.value = countriesResponse;
    });

    const removeAddress = async (address: HybrisAddress) => {
      await deleteAddress({ addressId: address.id });
      await fetch();
    };

    const billingAddresses = computed((): HybrisAddress[] => {
      const addresses = addressGetters.getAddresses(userAddresses.value, { isDefaultBillingAddress: true });

      return mappedAddresses(addresses);
    });

    const additionalAddresses = computed((): HybrisAddress[] => {
      const addresses = addressGetters.getAddresses(userAddresses.value, { isDefaultBillingAddress: false });

      return mappedAddresses(addresses);
    });

    const mappedAddresses = (addresses: HybrisAddress[]) => {
      return addresses?.map((address) => ({
        ...address,
        countryName: countries.value
          .find(({ id }) => id === address?.country.isocode)
          ?.full_name_locale
          ?? address?.country.isocode,
      }));
    }

    onMounted(() => breadcrumbsStore.setBreadcrumbs([
      {
        text: i18n.t('Address Book') as string,
        link: localePath({ name: 'customer-my-address-book' }),
      }
    ]));

    return {
      countries,
      userAddresses,
      removeAddress,
      billingAddresses,
      additionalAddresses,
      additionalAddressesTitle,
      isRemoveAddressSidebarOpened: computed(() => isRemoveAddressSidebarOpened.value),
      toggleRemoveAddressSidebar,
      setRemovableAddress,
      removableAddress,
      ...addressGetters,
    };
  },
});
