






































import { toRef, computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { HybrisAddress } from '@hybris/types';
import addressGetters from '~/integrations/hybris/src/getters/hybrisAddressesGetters';

export default defineComponent({
  name: 'HybrisUserAddressDetails',
  props: {
    address: {
      type: Object as PropType<HybrisAddress>,
      required: true,
    },
  },
  setup(props) {
    const address = toRef(props, 'address');

    const userAddress = computed(() => ({
      firstName: addressGetters.getFirstName(address.value),
      lastName: addressGetters.getLastName(address.value),
      line1: addressGetters.getStreetName(address.value),
      line2: addressGetters.getApartmentNumber(address.value),
      postalCode: addressGetters.getPostCode(address.value),
      city: addressGetters.getCity(address.value),
      province: addressGetters.getProvince(address.value) || '',
      country: addressGetters.getCountryName(address.value),
      phone: addressGetters.getPhone(address.value),
      isDefault: addressGetters.isDefault(address.value),
      isDefaultShipping: addressGetters.isDefaultShipping(address.value),
      isDefaultBilling: addressGetters.isDefaultBilling(address.value),
    }));

    return {
      userAddress,
    };
  },
});
