import { computed, reactive, ref, useContext } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useUiNotification } from '~/composables';
import type {
  RemoveHybrisAddressInterface,
  UseHybrisAddressesErrors,
  UseHybrisAddressesInterface,
  UseHybrisAddressesParamsInput,
} from '@hybris/composables/useHybrisAddresses/useHybrisAddresses';
import type {
  HybrisAddress,
  HybrisCustomerAddressBook,
  HybrisCustomerAddressApiResponse,
} from '@hybris/types';
import addressGetters from '@hybris/getters/hybrisAddressesGetters';

const state = reactive<RemoveHybrisAddressInterface>({
  isRemoveAddressSidebarOpened: false,
  removableAddress: null,
});

export function useHybrisAddresses(): UseHybrisAddressesInterface {
  const error: Ref<UseHybrisAddressesErrors> = ref({
    getAddressBook: null,
    getAddress: null,
    addNewAddress: null,
    deleteAddress: null,
    updateAddress: null,
  });
  const loading: Ref<boolean> = ref(false);
  const { app } = useContext();
  const { send: sendNotification } = useUiNotification();

  const getAddressBook = async (): Promise<HybrisAddress[]> => {
    Logger.debug('[Magento] load user addresses');
    let results = null;

    try {
      loading.value = true;

      const { data }: { data: HybrisCustomerAddressBook } = await app.context.$vsf.$hybris.api.getAddressBook();

      results = addressGetters.getMappedAddresses(data);

      Logger.debug('[Magento] load user addresses results:', results);
      error.value.getAddressBook = null;
    } catch (err) {
      error.value.getAddressBook = err;
      Logger.error('Magento] load user addresses error', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const getAddress = async (params: { addressCode: string }): Promise<HybrisCustomerAddressApiResponse> => {
    Logger.debug('[Magento] load user address');
    let results = null;

    try {
      loading.value = true;
      const { data } = await app.context.$vsf.$hybris.api.getAddress({ addressCode: params.addressCode });

      results = data;
      Logger.debug('[Magento] load user addresses results:', results);
      error.value.getAddress = null;
    } catch (err) {
      error.value.getAddress = err;
      Logger.error('Magento] load user addresses error', err);
    } finally {
      loading.value = false;
    }

    return results;
  }

  const addNewAddress = async (params:UseHybrisAddressesParamsInput): Promise<HybrisCustomerAddressApiResponse> => {
    Logger.debug('[Magento] save user address:', params.addressForm);
    let results = null;

    try {
      loading.value = true;
      const { data }: { data: HybrisCustomerAddressApiResponse } = await app.context.$vsf.$hybris.api.addNewAddress(
        params.addressForm,
      );

      results = data;
      Logger.debug('[Magento] save user address results:', params.addressForm);
      error.value.addNewAddress = null;

      sendNotification({
        id: Symbol('address_save'),
        type: 'success',
        message: '',
        icon: 'success',
        persist: false,
        title: app.i18n.t(
          'We\'ve added a new address to your account'
        ) as string,
      });

      if (params.isDefaultShippingChanged) {
        sendNotification({
          id: Symbol('address_default_update'),
          type: 'success',
          message: '',
          icon: 'success',
          persist: false,
          title: app.i18n.t(
            'We\'ve updated your default delivery address'
          ) as string,
        });
      }
    } catch (err) {
      error.value.addNewAddress = err;
      Logger.error('[Magento] save user address error:', err);
      sendNotification({
        id: Symbol('address_save_error'),
        type: 'danger',
        message: '',
        icon: 'danger',
        persist: false,
        title: app.i18n.t(
          'We couldn\'t add your address. Please try again later',
        ) as string,
      });
    } finally {
      loading.value = false;
    }

    return results;
  };

  const updateAddress = async (params: UseHybrisAddressesParamsInput): Promise<HybrisCustomerAddressApiResponse> => {
    Logger.debug('[Magento] update user address:', params.addressForm);
    let results = null;

    try {
      loading.value = true;
      const { data }: { data: HybrisCustomerAddressApiResponse } = await app.context.$vsf.$hybris.api.updateAddress(
        { ...params.addressForm }
      );

      results = data
      Logger.debug('[Magento] update user address results:', results);
      error.value.updateAddress = null;

      if (params.isDefaultShippingChanged) {
        sendNotification({
          id: Symbol('address_default_update'),
          type: 'success',
          message: '',
          icon: 'success',
          persist: false,
          title: app.i18n.t(
            'We\'ve updated your default delivery address'
          ) as string,
        });
      }

      sendNotification({
        id: Symbol('address_update'),
        type: 'success',
        message: '',
        icon: 'success',
        persist: false,
        title: app.i18n.t(
          'We\'ve updated your address'
        ) as string,
      });
    } catch (err) {
      error.value.updateAddress = err;
      Logger.error('[Magento] update user address error:', err);

      sendNotification({
        id: Symbol('address_update_error'),
        type: 'danger',
        message: '',
        icon: 'danger',
        persist: false,
        title: app.i18n.t(
          'We couldn\'t update your address. Please try again later',
        ) as string,
      });
    } finally {
      loading.value = false;
    }

    return results;
  };

  const deleteAddress = async (params: { addressId: string }): Promise<HybrisCustomerAddressApiResponse> => {
    Logger.debug('[Magento] remove user address:', params.addressId);
    let results = null;

    try {
      loading.value = true;
      const { data }: { data: HybrisCustomerAddressApiResponse } = await app.context.$vsf.$hybris.api.deleteAddress(
        { addressId: params.addressId }
      );

      results = data;
      Logger.debug('[Magento] remove user address results:', results);
      error.value.deleteAddress = null;

      sendNotification({
        id: Symbol('address_remove'),
        type: 'success',
        message: '',
        icon: 'success',
        persist: false,
        title: app.i18n.t(
          'We\'ve removed an address from your account'
        ) as string,
      });
    } catch (err) {
      error.value.deleteAddress = err;
      Logger.error('[Magento] remove user address error:', err);
      sendNotification({
        id: Symbol('address_remove_error'),
        type: 'danger',
        message: '',
        icon: 'danger',
        persist: false,
        title: app.i18n.t(
          'We couldn\'t delete your address. Please try again later',
        ) as string,
      });
    } finally {
      loading.value = false;
      toggleRemoveAddressSidebar();
    }

    return results;
  };

  const toggleRemoveAddressSidebar = (): void => {
    state.isRemoveAddressSidebarOpened = !state.isRemoveAddressSidebarOpened;
  };

  const closeRemoveAddressSidebar = (): void => {
    state.isRemoveAddressSidebarOpened = false;
  };

  const setRemovableAddress = (params: HybrisAddress): void => {
    state.removableAddress = params;
    toggleRemoveAddressSidebar();
  }

  return {
    getAddressBook,
    getAddress,
    addNewAddress,
    deleteAddress,
    updateAddress,
    error,
    loading,
    toggleRemoveAddressSidebar,
    closeRemoveAddressSidebar,
    isRemoveAddressSidebarOpened: computed(() => state.isRemoveAddressSidebarOpened),
    removableAddress: computed(() => state.removableAddress),
    setRemovableAddress,
  };
}

export * from './useHybrisAddresses';
export default useHybrisAddresses;
