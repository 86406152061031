














































import { computed, defineComponent, PropType, toRef } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import Modal from '@theme/components/organisms/Modal.vue';
import useHybrisAddresses from '@hybris/composables/useHybrisAddresses';
import type { HybrisAddress } from '@hybris/types';
import addressGetters from '@hybris/getters/hybrisAddressesGetters';
import HybrisUserAddressDetails from '@hybris/components/organisms/HybrisUserAddressDetails.vue';

export default defineComponent({
  name: 'RemoveHybrisAddressSidebar',
  components: {
    Modal,
    SfButton,
    HybrisUserAddressDetails,
  },
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Object as PropType<HybrisAddress | null>,
      default: null,
    },
  },
  setup(props) {
    const address = toRef(props, 'address');
    const { closeRemoveAddressSidebar } = useHybrisAddresses();
    const isDefaultShipping = computed(() => addressGetters.isDefaultShipping(address.value));

    return {
      isDefaultShipping,
      closeRemoveAddressSidebar,
    }
  }
})
