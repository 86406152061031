























import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'EmptySection',
  components: {
    SfButton,
  },
  props: {
    link: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
})
